import axiosInstance from "utils/axiosInterceptor";

export function list(data: any) {
  return axiosInstance({
    url: "/transactionHistoryAdmin?" + data,
    method: "get",
  });
}
export function updatePaymentStatus(
  id: number,
  status: number,
  brandId: number,
  price: number
) {
  return axiosInstance({
    url: "/purchasePlan/updateStatus/" + id,
    method: "PUT",
    data: { status, brandId, price },
  });
}

export function downloadInvoiceCampaign(data: any) {
  return axiosInstance({
    url: "/payment/createInvoiceManually",
    method: "POST",
    data: data,
  });
}

export const apiMethod = {
  list,
  updatePaymentStatus,
  downloadInvoiceCampaign
};
