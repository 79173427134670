import {
  Button,
  Pagination,
  Popconfirm,
  Table,
  Input,
  App,
  Image,
  Rate,
} from "antd";
import React, { useEffect, useState, useRef, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { RoutesObjInterface } from "routes";

interface ApiTypes {
  list: string
}

export interface TableComponentInterface {
  apiMethod: any;
  columns: any;
  editRoute?: RoutesObjInterface;
  viewRoute?: RoutesObjInterface;
  settingRoute?: RoutesObjInterface;
  deleteMsgTitle?: string;
  deleteMsgDescription?: string;
  data?: any;
  setData?: any;
  isPagination?: boolean;
  expandableView?: any;
  otherGetDataFunc?: any;
  reloadTable?: number;
  apiKeyNames: ApiTypes;
  loading?: boolean;
  customAction?: boolean;
  classname_table?: string;
  isTransactionHistory?: boolean;
}

const TableComponent: React.FC<TableComponentInterface> = ({
  apiMethod,
  columns,
  editRoute,
  viewRoute,
  settingRoute,
  deleteMsgTitle,
  deleteMsgDescription,
  data,
  setData,
  isPagination = true,
  expandableView,
  otherGetDataFunc,
  reloadTable,
  apiKeyNames,
  loading,
  customAction = false,
  classname_table = '',
  isTransactionHistory = false
}) => {
  const navigate = useNavigate();
  const callApi = !data;
  const [dataSource, setDataSource] = useState<any>(data);
  const [params, setParams] = useState<any>();
  const { message } = App.useApp();
  const tableRef = useRef(null);

  useEffect(() => {
    if (callApi) {
      if (params) {
        searching();
      } else {
        setParams({
          page: 1,
          pageSize: 10,
          pagination: 1,
          sortBy: "id",
          sortDesc: "DESC",
        });
      }
    }
  }, [params]);

  useEffect(() => {
    if (reloadTable && reloadTable > 0) {
      searching();
    }
  }, [reloadTable]);

  const searching = () => {
    var param = {
      ...Object.keys(params)
        .filter((k) => params[k] != null)
        .reduce((a, k) => ({ ...a, [k]: params[k] }), {}),
    };
    if (otherGetDataFunc) {
      param = otherGetDataFunc(param);
    }
    apiMethod[apiKeyNames.list](new URLSearchParams(param).toString())
      .then((res: any) => {
        let responseData = res?.data?.result?.data?.reduce((acc: any, cur: any) => [...acc, Object?.keys(cur?.primaryCategory || {})?.length > 0 ? { ...cur, primaryCategory: cur?.primaryCategory?.title } : { ...cur }], [])
        setDataSource({ ...res?.data?.result, data: responseData });
      })
      .catch((err: any) => { });
  };

  const tableColumns = JSON.parse(JSON.stringify(columns)).map((e: any) => {
    if (e["dataType"] === "actions") {
      e["render"] = (val: any, row: any, index: number) => (
        <div className="flex">
          {editRoute && (
            <Button
              type="text"
              className="ant-btn-icon-only"
              onClick={() => {
                navigate(`${editRoute.path.replaceAll(":id", row.id)}`);
              }}
            >
              <EditOutlined />
            </Button>
          )}
          {viewRoute && (
            <Button
              type="text"
              className="ant-btn-icon-only"
              onClick={() => {
                navigate(`${viewRoute.path.replaceAll(":id", row.id)}`);
              }}
            >
              <EyeOutlined />
            </Button>
          )}
          {settingRoute && (
            <Button
              type="text"
              className="ant-btn-icon-only"
              onClick={() => {
                navigate(`${settingRoute.path.replaceAll(":id", row.id)}`);
              }}
            >
              <SettingOutlined />
            </Button>
          )}
          {deleteMsgTitle && (
            <Popconfirm
              placement="bottom"
              ok-text="Yes"
              cancel-text="No"
              onConfirm={() => onDelete(row.id)}
              title={deleteMsgTitle ?? "Delete the recod"}
              description={deleteMsgDescription ?? "Are you sure delete this record?"}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            >
              <Button type="text" className="ant-btn-icon-only">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )}
        </div>
      );
    } else if (e["dataType"] === "image") {
      e["render"] = (val: any, row: any, index: number) =>
        (val ?? "") === "" ? (
          <></>
        ) : (
          <Image height={40} width={80} className="object-contain" src={val} />
        );
    } else if (e["dataType"] === "round_image") {
      e["width"] = 40;
      e["render"] = (val: any, row: any, index: number) =>
        (val ?? "") === "" ? (
          <></>
        ) : (
          <Image
            height={40}
            width={40}
            className="object-cover	 rounded-full"
            src={val}
          />
        );
    } else if (e["dataType"] === "rate") {
      e["render"] = (val: any, row: any, index: number) => (
        <Rate disabled defaultValue={val} className="w-36" />
      );
    }
    return e;
  });

  const onDelete = async (id: number) => {
    apiMethod
      .deleteById(id)
      .then((res: any) => {
        message.success(res.data.message);
        searching();
      })
      .catch((err: any) => { });
  };

  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    if (sorter.order) {
      setParams({
        ...params,
        sortBy: sorter.field,
        sortDesc: sorter.order === "descend" ? "DESC" : "ASC",
      });
    } else {
      setParams({ ...params, sortBy: "id", sortDesc: "DESC" });
    }
  };

  return (
    <div className=" categorytable">
      {params && (
        <div className="py-2 px-4 flex sm:block items-center justify-between bg-card text-white rounded-md mb-2">
          <div className="pagination">
            <Pagination
              className="text-white"
              current={params.page}
              pageSize={params.pageSize}
              total={dataSource && dataSource.totalItems}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} Items`
              }
              pageSizeOptions={["10", "20", "50", "100"]}
              size="small"
              showSizeChanger
              showLessItems
              responsive
              onChange={(page, pageSize) => {
                setParams({ ...params, page, pageSize });
              }}
            />
          </div>
          <div className="flex space-x-2 items-center pt-0 sm:pt-2">
            <div>Search:</div>
            <Input
              autoComplete="off"
              onPressEnter={(e) => {
                setParams({
                  ...params,
                  search:
                    (e.target as HTMLInputElement).value === ""
                      ? null
                      : (e.target as HTMLInputElement).value,
                  page: 1,
                });
              }}
              onBlur={(e) => {
                if (!isTransactionHistory) {
                  setParams({
                    ...params,
                    search:
                      (e.target as HTMLInputElement).value === ""
                        ? null
                        : (e.target as HTMLInputElement).value,
                    page: 1,
                  });
                }
              }}
              onChange={(e) => {
                if (
                  (params.search ?? "") !== e.target.value &&
                  e.target.value === ""
                ) {
                  setParams({
                    ...params,
                    search: null,
                    page: 1,
                  });
                } else {
                  if (isTransactionHistory) {
                    setParams({
                      ...params,
                      search:
                        (e.target as HTMLInputElement).value === ""
                          ? null
                          : (e.target as HTMLInputElement).value,
                      page: 1,
                    });
                  }

                }
              }}
              allowClear
            />
          </div>
        </div>
      )}
      <div className={`rounded-md overflow-hidden mt-2 ${classname_table}`}>
        <Table
          className={`ant-table-striped`}
          dataSource={dataSource && dataSource.data ? dataSource.data : dataSource}
          columns={customAction ? columns : tableColumns}
          rowKey={"id"}
          pagination={false}
          onChange={onChange}
          ref={tableRef}
          expandable={{
            expandedRowRender: expandableView,
            rowExpandable: (record) => expandableView,
          }}
          loading={loading}
        />
      </div>
    </div>
  );
};
export default TableComponent;
